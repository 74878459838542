import React from 'react';
import Container from 'Nori/Container';
import ISI from 'Custom/ISI';
import styles from 'Custom/ISI/ISIFooter.module.scss';

const ISIFooter = React.forwardRef(
  ({ reference: Reference, variant = '', ...props }, ref) => {
    const variantClassName = styles[variant] || styles.main;

    return (
      <Container
        className={`${styles.isi} ${variantClassName}`}
        role="complementary"
        aria-label="Footer Section, Indication and Important Safety Information"
      >
        <div
          ref={ref}
          data-design-visible="visible_vd"
          data-design-type="module"
          data-design-nori_id="ISI - Page Bottom"
          data-design-module_name="ISI - Page Bottom"
          data-design-module_no="00"
        >
          <ISI
            noriId="ISI - Page Bottom"
            moduleTitle="ISI - Page Bottom"
            {...props}
          />
        </div>
        {Reference && <Reference />}
      </Container>
    );
  }
);

export default ISIFooter;
