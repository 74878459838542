import React from 'react';
import styles from 'Custom/FooterDropdown/FooterHeader.module.scss';
import AngleDown from 'Images/brand-icons/angle-down.svg';
import Plus from 'Images/brand-icons/plus.svg';
import Minus from 'Images/brand-icons/minus.svg';

const FooterHeader = ({ isExpanded, children, ...props }) => {
  return (
    <div className={`${styles.header}`} {...props}>
      <button
        className={styles.accordionButton}
        {...props}
        aria-expanded={isExpanded}
        data-design-category="accordion_vd"
        data-design-action={isExpanded ? 'Close' : 'Expand'}
      >
        <span className={styles.faqItemTitle}>{children}</span>
        {isExpanded ? <Minus /> : <Plus />}
      </button>
    </div>
  );
};

export default FooterHeader;
