import React from 'react';
import Head from 'next/head';
import SCRIPTS from './Scripts';
import { useRouter } from 'next/router';
import SCHEMAS from './Schemas';

const BASE_PATH = process.env.NEXT_PUBLIC_BASE_PATH; // URL to create canonicals
const GOOGLE_SITE_VERIFICATION =
  process.env.NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION;

const DynamicHead = ({
  title,
  keywords,
  description,
  robots,
  hreflang,
  showChatbox,
}) => {
  const { pathname } = useRouter();
  const schema = SCHEMAS[pathname.replaceAll('/', '') || '/'] || null;
  const canonical =
    pathname === '/' ? `${BASE_PATH}/` : `${BASE_PATH}${pathname}/`;

  return (
    <Head>
      <title>{title}</title>
      {SCRIPTS}
      {showChatbox && (
        <script
          id="rxscript"
          key="rxscript"
          src="https://in.rxengage.app/rxdefine.js/rxdefine.js?id=e49d049f-58f1-4684-a7ff-1d15edd68322"
        />
      )}
      {schema}
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      {GOOGLE_SITE_VERIFICATION && (
        <meta
          name="google-site-verification"
          content={GOOGLE_SITE_VERIFICATION}
        />
      )}
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="robots" content={robots} />
      <link rel="canonical" href={canonical} />
      {hreflang && (
        <link rel="alternate" hrefLang={hreflang} href={canonical} />
      )}
    </Head>
  );
};

DynamicHead.defaultProps = {
  title: 'Nori Site',
  description: 'Nori site',
  robots: 'index, follow',
};

export default DynamicHead;
