import styles from 'Custom/Header/StickyNavigation.module.scss';
import Link from 'next/link';
import { STICKY_CONTENT } from 'Custom/Header/Header.utils';
import { FIXED_ELEMENT_IDS } from 'Utils/hooks/useFocusAlwaysVisible';
import { finderHandlerClick } from 'Custom/Finder/utils';

const StickyNavigation = ({
  basic = false,
  content = STICKY_CONTENT,
  orphan,
}) => {
  const { navList, variant } = content;
  const variantClassName = styles[variant] || styles.main;

  return (
    <nav
      className={`${
        orphan ? styles.orphanStickyNavigation : styles.stickyNavigation
      } ${variantClassName}`}
      aria-label="Top navigation"
    >
      <div
        className={orphan ? styles.orphanStickyRow : styles.stickyRow}
        id={FIXED_ELEMENT_IDS.mobile}
      >
        {!basic && (
          <Link
            onClick={(e) => {
              if (navList.findDoctor['data-custom-redirect']) {
                finderHandlerClick(e);
              }
            }}
            className={styles.mainCta}
            data-design-type="clickAction"
            data-design-category="top_nav_vd"
            data-design-action="Button"
            data-design-nori_id="Navigation"
            data-design-module_name="Navigation"
            data-design-module_no="00"
            {...navList.findDoctor}
          />
        )}
        <Link
          data-design-type="clickAction"
          data-design-category="top_nav_vd"
          data-design-action="Download"
          data-design-nori_id="Navigation"
          data-design-module_name="Navigation"
          data-design-module_no="00"
          target="_blank"
          rel="noopener"
          {...navList.prescribingInfo}
        />
        <Link
          data-design-type="clickAction"
          data-design-category="top_nav_vd"
          data-design-action="Download"
          data-design-nori_id="Navigation"
          data-design-module_name="Navigation"
          data-design-module_no="00"
          target="_blank"
          rel="noopener"
          {...navList.patientInfo}
        />
      </div>
    </nav>
  );
};

export default StickyNavigation;
