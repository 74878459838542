import React, { useRef, useState } from 'react';
import styles from './NavigationLinks.module.scss';
import Link from 'next/link';
import ExternalLink from 'Images/brand-icons/external-link.svg';
import Carot from 'Images/brand-icons/angle-down.svg';
import { useRouter } from 'next/router';
import useKeydownHandler from 'Utils/hooks/useKeyboardHandler';
import { Links } from 'Custom/Header/Header.utils';

const MenuItem = ({
  href,
  label,
  isSubItem = false,
  submenu = false,
  target = null,
  ...props
}) => {
  let icon = submenu && <Carot className={styles.carot} />;

  if (target === '_blank') {
    icon = <ExternalLink />;
  }

  return href ? (
    <Link href={href} target={target} {...props}>
      <span
        data-design-type="clickAction"
        data-design-category="top_nav_vd"
        data-design-action="Link"
        data-design-label={label}
        data-design-nori_id="Navigation"
        data-design-module_name="Navigation"
        data-design-module_no="00"
      >
        {label}
      </span>
      {icon}
    </Link>
  ) : (
    <button
      className={styles.dropdownButton}
      data-design-type="clickAction"
      data-design-category="top_nav_vd"
      data-design-action="Button"
      data-design-label={label}
      data-design-nori_id="Navigation"
      data-design-module_name="Navigation"
      data-design-module_no="00"
      {...props}
    >
      {label}
      {icon}
    </button>
  );
};

const NavigationLinks = ({ links = Links, alternative = false }) => {
  const { pathname } = useRouter();
  const liRefs = useRef([]);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };
  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const handleClick = (e) => {
    const primaryMenuItem = e.target.closest(`.${styles.listSubmenu}`);
    if (primaryMenuItem) {
      primaryMenuItem.classList.toggle(styles.opened);
    }
  };

  useKeydownHandler(() => {
    liRefs.current.map((li) => {
      li.classList.remove(styles.opened);
    });
  }, 'Escape');

  const handleBlur = (index) => (event) => {
    const { relatedTarget } = event;

    const currentListItem = liRefs.current[index];
    if (!relatedTarget || !currentListItem.contains(relatedTarget)) {
      currentListItem.classList.remove(styles.opened);
    }
  };

  const menu = links.map((item, index) => {
    const { submenu = null } = item;
    const isActive =
      pathname === item.href ||
      (submenu && submenu.some((i) => i.href === pathname));

    const listClass = submenu ? styles.listSubmenu : styles.list;

    return (
      <li
        key={`li-${index}`}
        className={`${
          isActive ? `${styles.active} ${listClass}` : `${listClass}`
        } ${
          hoveredIndex !== null && hoveredIndex !== index
            ? `${styles.disabled}`
            : ''
        }`}
        onBlur={handleBlur(index)}
        ref={(el) => (liRefs.current[index] = el)}
        onMouseEnter={() => handleMouseEnter(index)}
        onMouseLeave={handleMouseLeave}
      >
        <MenuItem {...item} {...(submenu && { onClick: handleClick })} />
        {submenu && (
          <ul className={styles.dropdown}>
            {submenu.map((subitem, subindex) => (
              <li
                key={`li-${index}-${subindex}`}
                className={subitem.href === pathname ? `${styles.active}` : ''}
              >
                <MenuItem isSubItem {...subitem} />
              </li>
            ))}
          </ul>
        )}
      </li>
    );
  });

  return (
    <ul className={`${styles.mainList} ${alternative ? styles.altList : ''}`}>
      {menu}
    </ul>
  );
};

export default NavigationLinks;
