import React from 'react';
import ModalSmall from 'Nori/Modal/ModalSmall';
import CloseIcon from 'Images/brand-icons/close-modal-nexplanon.svg';
import ISI from 'Custom/ISI';
import { ISI_MODAL_CONTENT } from 'Custom/Modal/ISIModal.utils';
import { useAppContext } from 'Providers/App';
import { UI_ELEMENTS } from 'Providers/App/elements';
import styles from 'Custom/Modal/ISIModal.module.scss';

const { modalISI: modalISIId } = UI_ELEMENTS;

const ISIModal = ({ content = ISI_MODAL_CONTENT, ...props }) => {
  const { updateElementState, getElementState, focusTrigger } = useAppContext();
  const {
    ARIA,
    moduleName,
    indicationTitle,
    isiTitle,
    indicationContent,
    content: isiCOntent,
  } = content;

  const modalISI = getElementState(modalISIId);

  const handleClose = () => {
    updateElementState(modalISIId, false);
    focusTrigger(modalISIId);
  };

  return (
    <ModalSmall
      className={styles.isiModal}
      isOpen={modalISI}
      modalTitle={ARIA.title}
      onClose={handleClose}
      data-design-visible="visible_vd"
      data-design-type="module"
      data-design-nori_id="Modal"
      data-design-module_name={moduleName}
      data-design-module_no="00"
      {...props}
    >
      <div className={styles.modalHeader}>
        <h3 className={styles.indicationTitle}>{indicationTitle}</h3>
        <h3 className={styles.isiTitle}>{isiTitle}</h3>
        <button
          aria-label={ARIA.closeModal}
          className={styles.closeIcon}
          onClick={handleClose}
          data-design-category="button_vd"
          data-design-type="clickAction"
          data-design-action="Close"
          data-design-label="close icon"
          data-design-nori_id="Modal"
          data-design-module_name={moduleName}
          data-design-module_no="00"
        >
          <CloseIcon />
        </button>
      </div>
      <p className={styles.indicationCopy}>{indicationContent}</p>
      <div className={styles.secondaryTitle}>
        <h3>{isiTitle}</h3>
      </div>
      <div className={styles.modalBody}>
        <div className={styles.isiWrapper}>
          <ISI
            noriId="Modal"
            moduleTitle={moduleName}
            isModal={true}
            content={isiCOntent}
          />
        </div>
      </div>
    </ModalSmall>
  );
};

export default ISIModal;
