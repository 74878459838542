export const EXTERNAL_LINK_MODAL_CONTENT = {
  ARIA: {
    closeModal: 'Close Modal',
    title: 'By clicking on this link, you will be leaving this site.',
  },
  title: 'By clicking on this link, you will be leaving this site.',
  children: (
    <>
      <p>This link will take you to a site outside of Organon.</p>
      <p>
        Organon does not review or control the content of any non-Organon site.
        Organon does not endorse and is not responsible for the accuracy,
        content, practices, or standards of any non-Organon site.
      </p>
    </>
  ),
  proceedButton: {
    children: 'Proceed',
    'data-design-label': 'Proceed',
    href: 'https://www.fda.gov/safety/medwatch-fda-safety-information-and-adverse-event-reporting-program',
  },
  cancelButton: {
    children: 'Cancel',
    'data-design-label': 'Cancel',
  },
};
