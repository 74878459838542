import React, { useEffect } from 'react';
import Script from 'next/script';
import DynamicHead from 'Nori/DynamicHead';
import { useAppContext } from 'Providers/App';
import { UI_ELEMENTS } from 'Providers/App/elements';
import { handleGigyaReady } from 'Utils/hooks/useGigyaService';
import { pageview } from 'Utils/hooks/usePageView';

const API_KEY_MAP = {
  production: process.env.NEXT_PUBLIC_EPSILON_FORM_API_KEY_PRODUCTION,
  development: process.env.NEXT_PUBLIC_EPSILON_FORM_API_KEY_STAGE,
  'stage-terms': process.env.NEXT_PUBLIC_EPSILON_FORM_API_KEY_TERMS_STAGE,
};

const API_KEY =
  API_KEY_MAP[process.env.NEXT_PUBLIC_ENV] || API_KEY_MAP.development;

const BaseLayout = ({
  head = {},
  hideISI = false,
  isiRef,
  children,
  showChatbox,
}) => {
  const { updateElementState } = useAppContext();

  const scrollHandler = () => {
    if (!isiRef.current) {
      return;
    }

    const domRect = isiRef.current.getBoundingClientRect();

    const isInViewPort =
      domRect.top <=
      (window.innerHeight || document.documentElement.clientHeight);
    updateElementState(UI_ELEMENTS.isISIVisible, isInViewPort);
  };

  useEffect(() => {
    if (!hideISI) {
      window.addEventListener('scroll', scrollHandler, true);
      scrollHandler();
    }

    const search = new URLSearchParams(window.location.search);
    if (search.get('request') === 'youtube') {
      updateElementState(UI_ELEMENTS.modalISI, true);
    }

    if (search.get('request') === 'labeling') {
      updateElementState(UI_ELEMENTS.modalPDF, true);
      updateElementState(UI_ELEMENTS.pageableModalPDF, false);
    }

    return () => {
      window.removeEventListener('scroll', scrollHandler, true);
    };
  }, []);

  useEffect(() => {
    if (document.body) {
      document.body.classList.toggle('hideChatbox', !showChatbox);
    }
  }, [showChatbox]);

  const handleSessionEvent = handleGigyaReady(pageview);

  return (
    <>
      <DynamicHead {...head} showChatbox={showChatbox} />
      {children}

      <Script
        id="gigya"
        src={`https://cdns.gigya.com/js/gigya.js?apikey=${API_KEY}`}
        onReady={handleSessionEvent}
      />
    </>
  );
};

export default BaseLayout;
