import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useAppContext } from 'Providers/App';
import { UI_ELEMENTS } from 'Providers/App/elements';

const TIMEOUT_LIMIT = 5000;
const CHECK_INTERVAL = 100;

export const PATIENT_HUB_CONFIG = {
  NO_ERROR_CODE: 0,
  REGISTRATION_URL: '/enrollment/',
  DASHBOARD_URL: '/enrollment/patient-hub/',
};

const DEFAULT_CONFIG = {
  screenSet: '',
  startScreen: 'gigya-subscribe-with-email-screen',
  containerID: 'container',
};

// Know if gigya is ready or not
export const useGigyaReady = (timeoutLimit = TIMEOUT_LIMIT) => {
  const [isGigyaReady, setIsGigyaReady] = useState(false);
  const [timedOut, setTimedOut] = useState(false);

  useEffect(() => {
    let checkTimeoutId, timeoutId;

    const checkGigyaReady = () => {
      if (window.gigya?.isReady) {
        setIsGigyaReady(true);
        clearTimeout(timeoutId);
      } else {
        checkTimeoutId = setTimeout(checkGigyaReady, CHECK_INTERVAL);
      }
    };

    if (!timedOut) {
      timeoutId = setTimeout(() => {
        setTimedOut(true);
      }, timeoutLimit);

      checkGigyaReady();
    }

    return () => {
      clearTimeout(timeoutId);
      clearTimeout(checkTimeoutId);
    };
  }, [timeoutLimit, timedOut]);

  return isGigyaReady;
};

// Show a screen or bind handlers when gigya is ready
export const useGigyaService = (config, eventHandlers = null) => {
  const [isGigyaReady, setIsGigyaReady] = useState(false);
  const serviceConfig = { ...DEFAULT_CONFIG, ...config };

  useEffect(() => {
    const onServiceReady = function () {
      setIsGigyaReady(true);
      window.gigya.accounts.showScreenSet(serviceConfig);

      if (eventHandlers) {
        window.gigya.accounts.addEventHandlers(eventHandlers);
      }
    };

    if (window.gigya?.isReady) {
      onServiceReady();
    } else {
      window.onGigyaServiceReady = onServiceReady;
    }
  }, [config, eventHandlers]);

  return isGigyaReady;
};

// Check if the user has an active session or not
export const useGigyaSessionVerify = (customCallback) => {
  const isGigyaReady = useGigyaReady();
  const { updateElementState } = useAppContext();

  useEffect(() => {
    if (isGigyaReady) {
      window.gigya.accounts.session.verify({
        callback: function (response) {
          updateElementState(
            UI_ELEMENTS.isLoggedIn,
            response.errorCode === PATIENT_HUB_CONFIG.NO_ERROR_CODE
          );
          customCallback(response);
        },
      });
    }
  }, [isGigyaReady]);

  return isGigyaReady;
};

export const createHandleOpenLogin = () => {
  const { getElementState } = useAppContext();
  const router = useRouter();

  return () => {
    const isLoggedIn = getElementState(UI_ELEMENTS.isLoggedIn);
    if (!isLoggedIn) {
      window.gigya.accounts.showScreenSet({
        screenSet: 'NexplanonPatientPortalHub',
        startScreen: 'gigya-login-screen',
      });

      return;
    }

    router.push(PATIENT_HUB_CONFIG.DASHBOARD_URL);
  };
};

export const handleGigyaReady = (customCallback) => {
  return () => {
    const url = `${window.location.pathname}/`.replaceAll('//', '/');

    window.gigya?.accounts?.session.verify({
      callback: function (response) {
        const isLoggedIn =
          response.errorCode === PATIENT_HUB_CONFIG.NO_ERROR_CODE;

        customCallback(url, isLoggedIn);
      },
    });
  };
};

export default useGigyaService;
