import React from 'react';
import Button from 'Nori/Button';
import ButtonCaption from 'Nori/Button/ButtonCaption';
import ModalSmall from 'Nori/Modal/ModalSmall';
import CloseIcon from 'Images/brand-icons/close-modal-nexplanon.svg';
import { useAppContext } from 'Providers/App';
import { EXTERNAL_LINK_MODAL_CONTENT } from 'Custom/Modal/ExternalLinkModal.utils';
import { UI_ELEMENTS } from 'Providers/App/elements';
import styles from 'Custom/Modal/ExternalLinkModal.module.scss';

const { modalExternalLink: modalExternalLinkId } = UI_ELEMENTS;

const ExternalLinkModal = ({
  content = EXTERNAL_LINK_MODAL_CONTENT,
  ...props
}) => {
  const { updateElementState, getElementState, focusTrigger } = useAppContext();
  const { ARIA, title, children, proceedButton, cancelButton } = content;

  const modalExternalLink = getElementState(modalExternalLinkId);

  const handleClose = () => {
    updateElementState(modalExternalLinkId, false);
    focusTrigger(modalExternalLinkId);
  };

  return (
    <ModalSmall
      className={`${styles.external}`}
      isOpen={modalExternalLink}
      modalTitle={ARIA.title}
      onClose={handleClose}
      data-design-visible="visible_vd"
      data-design-type="module"
      data-design-nori_id="Modal"
      data-design-module_name={title}
      data-design-module_no="00"
      {...props}
    >
      <button
        aria-label={ARIA.closeModal}
        className={styles.closeIcon}
        onClick={handleClose}
        data-design-category="button_vd"
        data-design-type="clickAction"
        data-design-action="Close"
        data-design-label="close icon"
        data-design-nori_id="Modal"
        data-design-module_name={title}
        data-design-module_no="00"
      >
        <CloseIcon />
      </button>
      <div className={styles.modalContent}>
        <h4>{title}</h4>
        {children}

        <div className={styles.buttonBlock}>
          <ButtonCaption
            type="outlineSecondary"
            target="_blank"
            rel="noopener"
            onClick={handleClose}
            data-design-category="button_vd"
            data-design-type="clickAction"
            data-design-action="Link"
            data-design-nori_id="Modal"
            data-design-module_name={title}
            data-design-module_no="00"
            {...proceedButton}
          />
          <Button
            onClick={handleClose}
            data-design-category="button_vd"
            data-design-type="clickAction"
            data-design-action="Close"
            data-design-nori_id="Modal"
            data-design-module_name={title}
            data-design-module_no="00"
            {...cancelButton}
          />
        </div>
      </div>
    </ModalSmall>
  );
};

export default ExternalLinkModal;
