import React from 'react';
import ModalSmall from 'Nori/Modal/ModalSmall';
import Link from 'next/link';
import CloseIcon from '@/public/assets/images/brand-icons/close-modal-nexplanon.svg';
import { HEALTHCARE_MODAL_CONTENT } from 'Custom/Modal/HealthcareModal.utils';
import { useAppContext } from 'Providers/App';
import { UI_ELEMENTS } from 'Providers/App/elements';
import styles from 'Custom/Modal/HealthcareModal.module.scss';

const { modalHealthcare: modalHealthcareId } = UI_ELEMENTS;

const HealthcareModal = ({
  className = '',
  content = HEALTHCARE_MODAL_CONTENT,
  ...props
}) => {
  const { updateElementState, getElementState, focusTrigger } = useAppContext();
  const { ARIA, title, children, returnButton, confirmButton, bottomContent } =
    content;

  const modalHealthcare = getElementState(modalHealthcareId);

  const handleClose = () => {
    updateElementState(modalHealthcareId, false);
    focusTrigger(modalHealthcareId);
  };

  return (
    <ModalSmall
      className={`${styles.healthcareModal} ${className}`}
      isOpen={modalHealthcare}
      modalTitle={ARIA.title}
      onClose={handleClose}
      data-design-visible="visible_vd"
      data-design-type="module"
      data-design-nori_id="Modal"
      data-design-module_name={title}
      data-design-module_no="00"
      {...props}
    >
      <button
        aria-label={ARIA.closeModal}
        className={styles.closeIcon}
        onClick={handleClose}
        data-design-category="button_vd"
        data-design-type="clickAction"
        data-design-action="Close"
        data-design-label="close icon"
        data-design-nori_id="Modal"
        data-design-module_name={title}
        data-design-module_no="00"
      >
        <CloseIcon />
      </button>
      <div className={styles.modalContent}>
        <h4>{title}</h4>
        {children}
        <p>
          <button
            onClick={handleClose}
            data-design-category="content_link_vd"
            data-design-type="clickAction"
            data-design-action="Close"
            data-design-nori_id="Modal"
            data-design-module_name={title}
            data-design-module_no="00"
            className={styles.noButton}
            {...returnButton}
          />
        </p>
        <p>
          <Link
            onClick={handleClose}
            target="_blank"
            rel="noopener"
            data-design-category="content_link_vd"
            data-design-type="clickAction"
            data-design-action="Link"
            data-design-nori_id="Modal"
            data-design-module_name={title}
            data-design-module_no="00"
            {...confirmButton}
          />
          {confirmButton.caption && (
            <span className={styles.linkCaption}>{confirmButton.caption}</span>
          )}
        </p>
        {bottomContent}
      </div>
    </ModalSmall>
  );
};

export default HealthcareModal;
